import { render, staticRenderFns } from "./CronogramaEstudio.vue?vue&type=template&id=5ea11a40&"
import script from "./CronogramaEstudio.vue?vue&type=script&lang=js&"
export * from "./CronogramaEstudio.vue?vue&type=script&lang=js&"
import style0 from "./CronogramaEstudio.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VScrollXTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VBtn,VDialog,VIcon,VScrollXTransition,VSpacer,VTextarea,VTimeline})
