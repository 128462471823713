<template>
  <div>
    <div v-if="!noButton" class="d-flex mt-n6">
      <v-spacer></v-spacer>
      <v-btn text small @click.stop="addNote">
        <v-icon left>mdi-plus</v-icon>
        Añadir nota
      </v-btn>
    </div>

    <div class="mx-4" v-if="resolvePromise">
      <v-textarea
        filled
        dense
        no-resize
        v-model="nuevaNota"
        label="Nueva nota"
        persistent-hint
        hint="__cursiva__ **negrita**"
      >
      </v-textarea>
      <div class="d-flex mt-n4">
        <v-spacer></v-spacer>
        <v-btn @click.stop="resolvePromise(false)" small color="error"
          >Cancelar</v-btn
        >
        <v-btn
          @click.stop="resolvePromise(nuevaNota)"
          small
          class="ml-1"
          color="primary"
          >Crear nota</v-btn
        >
      </div>
    </div>

    <v-timeline dense>
      <v-scroll-x-transition group>
        <component
          :is="item"
          v-for="(item, i) in items.slice(0, sliced || items.lenght)"
          :key="i"
        />
      </v-scroll-x-transition>
      <div class="d-flex justify-center" v-if="items.length > sliced">
        <v-btn v-if="sliced" x-small text @click.stop="sliced = null">
          <v-icon left>mdi-chevron-down</v-icon>
          Mostrar más
        </v-btn>
        <v-btn v-else x-small text @click.stop="sliced = 10">
          <v-icon left>mdi-chevron-up</v-icon>
          Mostrar menos
        </v-btn>
      </div>
    </v-timeline>

    <v-dialog
      :value="detailsNota && detailsNota.id != null"
      @input="(v) => (v === false ? (detailsNota = Object.create(null)) : null)"
      max-width="500"
    >
      <DetallesAlerta
        @close="
          detailsNota = Object.create(null);
          $emit('reload');
        "
        :detailsNota="detailsNota"
        v-if="detailsNota"
      />
    </v-dialog>
  </div>
</template>

<script>
import { parseDate, getSituacionIcon } from "@/utils/index.js";
import Vue from "vue";
import StatusChip from "@/components/StatusChip.vue";
import { VChip, VIcon, VTimelineItem } from "vuetify/lib/components";
// import { Ripple } from "vuetify/lib/directives";

export default {
  props: {
    cronograma: Array,
    idEstudio: String | Number,
    noButton: Boolean,
    filterNotas: String,
  },
  components: {
    StatusChip,
    DetallesAlerta: () => import("@/components/DetallesAlerta.vue"),
  },
  data() {
    return {
      nuevaNota: null,
      resolvePromise: null,
      detailsNota: Object.create(null),
      items: [],

      sliced: 10,
    };
  },
  methods: {
    parseDate,
    parseNotes() {
      // console.trace("EXECUTE ==> ", this.cronograma.length)
      const getId = (function() {
        let i = 0;
        return () => i++;
      })();

      const tipoNotaIcon = (tipo) => {
        switch (tipo) {
          case "Aviso automático":
            return { color: "warning", icon: "mdi-email-fast-outline" };
          case "Nota":
            return { color: "info", icon: "mdi-note-multiple-outline" };
          case "Alerta":
            return { color: "warning", icon: "mdi-bell-outline" };
          case "Subida documentación":
            return { color: "indigo", icon: "mdi-paperclip" };
          case "Documento final":
            return { color: "primary", icon: "mdi-file-sign" };
          case "Autoestudio":
            return { color: "success", icon: "mdi-auto-fix" };
          default:
            return getSituacionIcon(tipo);
        }
      };
      const filter = this.filterNotas;

      let items = this.cronograma
        .filter((cron) => {
          if (filter == null) return true;
          switch (filter) {
            case "General":
              return cron.interno == "0";
            case "Interno":
              return cron.interno == "1";
            case "Notas":
              return cron.idNota != null;
            case "Contactos":
              return cron.idContacto != null;
            default:
              return true;
          }
        })
        .map((h, index, arr) => {
          const aux = { ...h, id: getId() };

          const openDetails = () => {
            this.detailsNota = aux;
          };

          const iconColor = this.$vuetify.theme.dark ? "white" : "black";

          let item = Vue.component("cronograma-item", {
            render() {
              return (
                <VTimelineItem
                  small
                  color=""
                  iconColor={tipoNotaIcon(aux.estado || aux.tipoContacto || aux.tipo).color}
                  class={{
                    "pb-2": true,
                    "cronograma-item": true,
                    ringing: aux.enviado == 1 && aux.apagado == 0,
                  }}
                  nativeOnClick={openDetails}
                  icon={
                    aux.enviado == 1
                      ? "mdi-bell-ring"
                      : tipoNotaIcon(aux.estado || aux.tipoContacto || aux.tipo).icon
                  }
                  icon-color={iconColor}
                >
                  <div class="pa-1">
                    {["General", "Interno"].includes(aux.tipo) && (
                      <span class="text-subtitle-1">
                        {index == arr.length - 1 ? "Creado como" : "Cambio a"}
                        <StatusChip value={aux.estado} class="mx-2" small />
                        por {aux.usuario}
                        {(aux.tipo == "General" || aux.tipo == "Interno") && (
                          <VChip
                            color={
                              aux.tipo == "General" ? "primary" : "secondary"
                            }
                            label
                            outlined
                            class="v-chip--active pa-1 ml-2"
                            x-small
                          >
                            {aux.tipo}
                          </VChip>
                        )}
                      </span>
                    )}

                    {['contacto'].includes(aux.tipo) && (
                      <span class="text-subtitle-1">
                        <StatusChip value={aux.tipoContacto} small />
                        { aux.respondida == false && <VIcon class="mx-1" color="error" right small>mdi-phone-off</VIcon> }
                        { aux.respondida != false && <span>&nbsp;&nbsp;&nbsp;</span> }
                        por {aux.usuario}
                      </span>
                    )}

                    {[
                      "Nota",
                      "Autoestudio",
                      "Subida documentación",
                      "Documento final",
                    ].includes(aux.tipo) && (
                      <span class="text-subtitle-1">
                        <strong>{aux.usuario}: </strong>
                        {aux.resumen || (
                          <small>
                            &nbsp;<i>Sin resumen</i>
                          </small>
                        )}
                      </span>
                    )}

                    {aux.tipo == "Alerta" && (
                      <div
                        class={{
                          "grey--text": aux.enviado == 1 && aux.apagado == 1,
                        }}
                      >
                        <span class="text-subtitle-1">
                          Alerta creada para{" "}
                          {parseDate(aux.fechaAlerta, false, true, true)}
                        </span>
                        <div>{aux.resumen}</div>
                        <div>
                          {aux.apagado == 1 && (
                            <VChip
                              color="success"
                              outlined
                              class="ml-2 v-chip--active"
                              x-small
                              label
                            >
                              <VIcon left>mdi-bell-sleep-outline</VIcon>
                              Apagado
                            </VChip>
                          )}
                          {aux.enviado == 1 && (
                            <VChip
                              color="success"
                              outlined
                              class="ml-2 v-chip--active"
                              x-small
                              label
                            >
                              <VIcon left>mdi-check-circle</VIcon>
                              Enviado
                            </VChip>
                          )}
                          {aux.enviado == -1 && (
                            <VChip
                              color="error"
                              outlined
                              class="ml-2 v-chip--active"
                              x-small
                              label
                            >
                              <VIcon left>mdi-close-circle</VIcon>
                              Anulada
                            </VChip>
                          )}
                        </div>
                      </div>
                    )}

                    <span
                      style="display: block; margin-bottom: 0px"
                      class="text-caption grey--text"
                    >
                      {parseDate(aux.fechaCambio, false, true, true)}
                    </span>
                  </div>
                </VTimelineItem>
              );
            },
          });

          const auxNext = { ...arr[index + 1], id: getId() };

          const diffDays = Math.floor(
            Math.abs(aux.fechaCambio - auxNext.fechaCambio) /
              (1000 * 60 * 60 * 24)
          );

          if (diffDays >= 4) {
            var diffTimeItem = Vue.component("cronograma-skip", {
              render() {
                return (
                  <VTimelineItem
                    color=""
                    class="pa-0"
                    small
                    icon="mdi-timer-sand"
                  >
                    <div class="d-flex align-center" style="height: 32px">
                      <div class="text--disabled"> ~ {diffDays} días </div>
                    </div>
                  </VTimelineItem>
                );
              },
            });
          }

          return [item, diffTimeItem];
        });

      this.items = items.flat().filter((x) => x !== undefined);
    },
    async addNote() {
      const promiseHelper = () =>
        new Promise(async (resolve) => {
          this.resolvePromise = resolve;
        });

      let nota = await promiseHelper();
      this.resolvePromise = null;
      if (nota === false) return;

      nota = nota.replace(/\*\*(\S(.*?\S)?)\*\*/gm, "<strong>$1</strong>"); //negrita
      nota = nota.replace(/\_\_(\S(.*?\S)?)\_\_/gm, "<i>$1</i>"); //cursiva

      axios({
        url: `${process.env.VUE_APP_API_URL}/cronograma/nuevaNota.php`,
        method: "POST",
        data: {
          idEstudio: this.idEstudio,
          nuevaNota: nota,
        },
      }).then((res) => {
        this.nuevaNota = null;
        this.$emit("reload");
      });
    },
  },
  watch: {
    cronograma: {
      immediate: true,
      handler(n, o) {
        this.parseNotes();
      },
    },
  },
};
</script>

<style lang="scss">
.theme--dark .cronograma-item {
  cursor: pointer;
  &:hover {
    background: #272727;
  }
}
.theme--light .cronograma-item {
  cursor: pointer;
  &:hover {
    background: #f1f1f1;
  }
}

.cronograma-item.ringing .v-icon {
  animation: ring 4s 0.7s ease-in-out;
  transform-origin: 50% 4px;
  animation-iteration-count: infinite;
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }
  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
</style>
